import React, { Component } from 'react';
import ReactDOM from 'react-dom';

import { getAvailableCharms, getCharmSvgSrc } from 'common/resources/charms';

class CharmsDemo extends Component {
  render() {
    return (
      <div>
        <p>All charms:</p>
        {
          getAvailableCharms().map((charmName) => (
            <li>{charmName} <img src={getCharmSvgSrc(charmName)} key={charmName} alt={charmName} /></li>
          ))
        }
      </div>
    );
  }
}

$(() => {
  ReactDOM.render(
    React.createElement(CharmsDemo),
    document.getElementById('component-demo')
  );
});
